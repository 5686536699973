import { render } from 'react-dom'
import { NewsletterForm } from '../components/NewsletterForm'

const renderIds = ['newsletter-form-footer', 'newsletter-form-notification']
const userEmail = App.State?.user?.email

document.addEventListener('DOMContentLoaded', () => {
  renderIds.forEach((id) => {
    if (document.getElementById(id))
      render(
        <NewsletterForm prefilledEmail={userEmail} />,
        document.getElementById(id)
      )
  })
})
