import { Formik } from 'formik'
import * as Yup from 'yup'
import useWindowDimensions from '../hooks/useWindowDimensions'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
})

export function NewsletterForm({ onSubmit = () => null, prefilledEmail }) {
  const dimensions = useWindowDimensions()

  function handleSubmit(values, actions) {
    actions.setStatus({ message: '' })
    actions.setSubmitting(true)

    App.Request.post('/subscribers.json', values)
      .then(({ data, status }) => {
        switch (status) {
          case 200:
            actions.resetForm()
            actions.setStatus({
              message: I18n.t('newsletter.success_message'),
            })
            onSubmit()
            break
          case 422:
            actions.setFieldError('email', data.error)
            break
          default:
            actions.setFieldError(
              'email',
              I18n.t('something_went_wrong_notification')
            )
            break
        }
      })
      .catch(() =>
        actions.setFieldError(
          'email',
          I18n.t('something_went_wrong_notification')
        )
      )
  }

  return (
    <Formik
      initialValues={{ email: prefilledEmail || '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      children={(props) => (
        <form
          onSubmit={props.handleSubmit}
          className={`${dimensions.width < 767 && 'mt-3'}`}
        >
          <div className="input-group rounded-8">
            <input
              type="text"
              name="email"
              onChange={(e) => {
                if (props.status) {
                  props.setStatus(undefined)
                }

                props.handleChange(e)
              }}
              value={props.values.email}
              disabled={prefilledEmail}
              className="form-control rounded-8 border-0 bg-light text-dark"
              placeholder={`${I18n.t('newsletter.placeholder')}...`}
              aria-label="Weekly newsletter email input"
            />
            <button
              type="submit"
              className={`btn btn-primary px-5 rounded-8 ${
                dimensions.width < 500 ? 'w-100 mt-3' : 'ms-3'
              }`}
              disabled={props.isSubmitting}
              id="newsletter-form-submit-button"
            >
              <i className="fa-solid fa-bell me-2" />
              {I18n.t('subscribe')}
            </button>
          </div>
          <p
            className="small mt-2 mb-0"
            style={{ color: '#858585' }}
            dangerouslySetInnerHTML={{ __html: I18n.t('by_clicking_submit') }}
          />

          {props.status && props.status.message && (
            <div className="text-primary">{props.status.message}</div>
          )}

          {props.errors.email && (
            <div className="text-danger">{props.errors.email}</div>
          )}
        </form>
      )}
    />
  )
}
